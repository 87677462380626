<template>
 
 
</template>

<script>
export default {
  data() {
    return {
      list: [],
      leagueList: [],
      adminCount: 0,
      leagueCount: 0,
      academiesCount: 0,
      playersCount: 0,
      selected: null,
      headerList: [],
      subheaderList: [],
    };
  },

  methods: {
    getCountTeams(data, item) {
      if (data && data[item.yearName] && data[item.yearName][item.name]) {
        return data[item.yearName][item.name];
      }
      return 0;
    },
    getPlayerCount(item) {
      let c = 0;
      for (const key in item) {
        if (
          (key != 'id', key != 'userName', key != 'name', key != 'allCount')
        ) {
          for (const k in item[key]) {
            if (typeof item[key][k] == 'number') {
              c = c + item[key][k];
            }
          }
        }
      }
      return c;
    },
    getData() {
      // this.$http
      //   .get(`years/findAllYearsAcademie?leaguesId=${this.selected}`)
      //   .then((res) => {
      //     this.headerList = res.data;
      //     this.subheaderList = [];
      //     if (this.headerList.length > 0) {
      //       for (const item of this.headerList) {
      //         for (const itm of item.yearsTeams) {
      //           this.subheaderList.push(itm);
      //         }
      //       }
      //       this.$http
      //         .get(`players/byLeaguesId/${this.selected}`)
      //         .then((res2) => {
      //           this.list = res2.data;
      //         });
      //     }
      //   });
      // this.$http.get(`league/home`).then((res) => {
      //   this.headerList = res.data;
      //   this.subheaderList = [];
      //   if (this.headerList.length > 0) {
      //     for (const item of this.headerList) {
      //       for (const itm of item.teams) {
      //         this.subheaderList.push({
      //           yearName: item.name,
      //           ...itm,
      //         });
      //       }
      //     }
      //     this.$http
      //       .get(`players/byLeaguesId/${this.selected}`)
      //       .then((res2) => {
      //         this.list = res2.data;
      //       });
      //   }
      // });
    },
    onToggle(value) {
      this.getData();
    },
  },
  created() {
    this.$http.post(`users/count`).then((res) => {
      this.adminCount = res.data;
    });
    this.$http.post(`league/count`).then((res) => {
      this.leagueCount = res.data;
    });
    this.$http.post(`academies/count`).then((res) => {
      this.academiesCount = res.data;
    });
    this.$http.post(`players/count`).then((res) => {
      this.playersCount = res.data;
    });
    this.$http.get(`league/home`).then((res) => {
      this.headerList = res.data.year;

      this.subheaderList = [];
      if (this.headerList.length > 0) {
        for (const item of this.headerList) {
          for (const itm of item.teams) {
            this.subheaderList.push({
              yearName: item.name,
              ...itm,
            });
          }
        }
      }
      this.list = [];

      this.$http
        .post(`players/byLeaguesId`, {
          id: res.data.id,
          list: res.data.list.map((el) => el.id),
        })
        .then((res2) => {
          const l = res2.data;
          console.log('farrajres2.data', res2.data);

          for (let item of res.data.list) {
            const lf = l.find((el) => el.id == item.id);
            console.log('farraj', lf);
            if (lf) {
              this.list.push({ ...item, allCount: lf.allCount });
            } else {
              this.list.push({ ...item, allCount: 0 });
            }
          }
          // this.list = res.data.list;

          console.log(this.list);
        });
      // this.leagueList = res.data.year;
      // this.selected =
      //   this.leagueList.length > 0 ? this.leagueList[0].id : null;
      // this.getData();
    });
  },
};
</script>

<style>
.overview-box {
  padding: 15px;
  color: #ffffff;
  min-height: 100px;
  border-radius: 4px;
  margin: 0 !important;
}
.overview-box .overview-box-title {
  font-weight: bold;
  width: 100%;
}
.overview-box .overview-box-title i {
  vertical-align: middle;
  font-size: 20px;
}
.overview-box .overview-box-title span {
  margin-right: 0.5em;
  vertical-align: middle;
}
.overview-box .overview-box-count {
  color: #ffffff;
  font-size: 24px;
  width: 100%;
  display: block;
  padding: 5px 0;
}
.overview-box.overview-box-1 {
  background-color: #007bff;
  border: solid 1px #007bff;
  color: #ffffff;
}
.overview-box.overview-box-2 {
  background-color: #28a745;
  border: solid 1px #28a745;
  color: #ffffff;
}
.overview-box.overview-box-3 {
  background-color: #fd7e14;
  border: solid 1px #fd7e14;
  color: #ffffff;
}
.overview-box.overview-box-4 {
  background-color: #6f42c1;
  border: solid 1px #6f42c1;
  color: #ffffff;
}
@media only screen and (max-width: 600px) {
  .overview-box {
    margin-bottom: 11px !important;
  }
}
</style>
